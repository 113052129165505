.result-progress-container {
  width: 25%; /* Adjusted width */
  margin: 20px 0;
  margin-top: 5px;
}

.label-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.result-progress-bar {
  flex-grow: 1;
  height: 30px; /* Height of the progress bar */
  background: #f3f3f3;
  border-radius: 5px;
  margin: 0 10px; /* Space between the labels and the progress bar */
  position: relative; /* Needed to position progress-value correctly */
}

.progress-fill {
  height: 100%;
  background: #008080;
  border-radius: 5px;
  transition: width 0.3s ease;
}

/* .progress-value {
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
  color: grey;
  font-size: 14px;
  font-weight: bold;
} */

.progress-label {
  font-size: 12px;
  font-weight: bold;
  color: #333;
}

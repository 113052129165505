/* components/TaskCompletePage.css */
.task-complete-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(to right, #504994, #756e90);
    color: white;
    font-family: 'Arial', sans-serif;
    text-align: center;
    padding: 20px;
  }
  
  .task-complete-content {
    background: rgba(255, 255, 255, 0.1);
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  }
  
  .task-complete-title {
    font-size: 2.5em;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .task-complete-message {
    font-size: 1.2em;
    margin-bottom: 30px;
    line-height: 1.5;
  }
  
  .task-complete-button {
    background-color: white;
    color: #fc575e;
    border: none;
    padding: 10px 20px;
    font-size: 1.1em;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .task-complete-button:hover {
    background-color: #fc575e;
    color: white;
    transform: scale(1.05);
  }
  
/* .transitionStep {
    filter: grayscale(1);
  }
  
  .transitionStep.accomplished {
    filter: grayscale(0);
  }


   */
   .progress {
    background-color: #f5f5f5;
    border-radius: 3px;
    box-shadow: none;
  }
  
  .progress.vertical {
    position: relative;
    width: 30px;
    height: 230px;
    display: inline-block;
    margin-right: 10px;
  }
  
  .progress-bar {
    background-color: #00A6C7; 
    width: 100%;
    position: absolute;
    bottom: 0;
    transition: height 0.6s ease;
    /* Ensure z-index is high enough if other elements overlap */
    z-index: 1; 
  }
  
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }


  .progress-container {
    padding: 10px;
    background-color: inherit; 
    border-radius: 50px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
    z-index: 1000; 
  }
  
  .progress-text {
    font-size: 30px;
    font-weight: bold;
    color: rgb(44, 96, 44); 
    font-family: 'Arial', sans-serif;
    letter-spacing: 0.5px;
  }
  

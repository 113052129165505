.result-message {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Professional yet modern font */
    font-size: 1.5rem;
    color: #008080; /* Teal Blue for a balanced look */
    background-color: #e0f7fa; /* Light teal background for a cohesive theme */
    padding: 20px;
    border-radius: 15px;
    border: 2px solid #008080; /* Matching border color */
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 128, 128, 0.2); /* Soft shadow in the same color family */
    margin: 20px auto;
    width: fit-content;
    transition: transform 0.3s ease-in-out;
    animation: fadeIn 1s ease-out;
    font-weight: bold;
    margin-bottom: 0;
  }
  
  .result-message:hover {
    transform: scale(1.05); 
  }
  
  @keyframes fadeIn {
    0% {
        transform: scale(0.8);
        opacity: 0;
      }
      60% {
        transform: scale(1.1);
        opacity: 1;
      }
      100% {
        transform: scale(1);
      }
  }
  

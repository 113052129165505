/* Apply a general reset to avoid inconsistencies */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .login-wrap {
    font-family: Arial, sans-serif;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
  } 
  
  .login-container {
    background: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 400px;
    width: 100%;
  }
  
  h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  input[type="text"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 28px;
    font-weight: bold;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  input[type="text"]:focus {
    border-color: #2196f3;
  }
  
  button {
    width: 100%;
    padding: 10px;
    background-color: #2196f3;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #1976d2;
  }
  
  .error-message {
    text-align: center;
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }
  